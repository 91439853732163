/* global clearInterval, console, setInterval */


function convertDate(str){
  /**
   * Convert a string to a date in Excel format
   * @param {string} str A string representing a date
   * @return {number} A number representing a date in Excel format
   */
  var date = new Date(Date.parse(str));
  var converted = 25569.0 + ((date.getTime() - (date.getTimezoneOffset() * 60 * 1000)) / (1000 * 60 * 60 * 24));
  return converted;
}

/**
 * Query the IsoStream REST API.
 * 
 * ISOSTREAMQUERY function requires a path to the API endpoint, and optionally any number of optional parameters.
 * Query parameters can be specified as a pair of a name and a value.  Additionally, the function has optional parameters
 * which are not passed to the API
 * =ISOSTREAMQUERY(path, arg1, value1, arg2, value2, ...) 
 * 
 * Optional parameters:
 * include_headers: If true, the first row of the response will be the column names.  Default is true.
 * columns: A comma separated list of columns to return.  If not specified, all columns will be returned.
 * as_date_columns: A comma separated list of columns to convert to dates.  If not specified, no columns will be converted.
 * sort: A column to sort by.  If the column name is preceded by a "-", the sort will be descending.  If not specified, no sorting will be done.
 * refresh: A number of minutes to wait before refreshing the data.  If not specified, the data will not be refreshed.
 * dummy: A dummy value used for streaming functionality.
 * 
 * @customfunction
 * @param {string} path The API REST Path
 * @param {string} [arg1_name] Optional query parameter name
 * @param {string} [arg1_value] Optional query parameter value
 * @param {string} [arg2_name] Optional query parameter name
 * @param {string} [arg2_value] Optional query parameter value
 * @param {string} [arg3_name] Optional query parameter name
 * @param {string} [arg3_value] Optional query parameter value
 * @param {string} [arg4_name] Optional query parameter name
 * @param {string} [arg4_value] Optional query parameter value
 * @param {string} [arg5_name] Optional query parameter name
 * @param {string} [arg5_value] Optional query parameter value
 * @param {string} [arg6_name] Optional query parameter name
 * @param {string} [arg6_value] Optional query parameter value
 * @param {string} [arg7_name] Optional query parameter name
 * @param {string} [arg7_value] Optional query parameter value
 * @param {string} [arg8_name] Optional query parameter name
 * @param {string} [arg8_value] Optional query parameter value
 * @param {string} [arg9_name] Optional query parameter name
 * @param {string} [arg9_value] Optional query parameter value
 * @param {string} [arg10_name] Optional query parameter name
 * @param {string} [arg10_value] Optional query parameter value
 * @param {string} [arg11_name] Optional query parameter name
 * @param {string} [arg11_value] Optional query parameter value
 * @param {string} [arg12_name] Optional query parameter name
 * @param {string} [arg12_value] Optional query parameter value
 * @param {string} [arg13_name] Optional query parameter name
 * @param {string} [arg13_value] Optional query parameter value
 * @param {string} [arg14_name] Optional query parameter name
 * @param {string} [arg14_value] Optional query parameter value
 * @param {string} [arg15_name] Optional query parameter name
 * @param {string} [arg15_value] Optional query parameter value
 * @param {string} [arg16_name] Optional query parameter name
 * @param {string} [arg16_value] Optional query parameter value
 * @param {string} [arg17_name] Optional query parameter name
 * @param {string} [arg17_value] Optional query parameter value
 * @param {string} [arg18_name] Optional query parameter name
 * @param {string} [arg18_value] Optional query parameter value
 * @param {string} [arg19_name] Optional query parameter name
 * @param {string} [arg19_value] Optional query parameter value
 * @param {string} [arg20_name] Optional query parameter name
 * @param {string} [arg20_value] Optional query parameter value
 * @param {CustomFunctions.StreamingInvocation<number[][]>} [invocation] data returned from the API
 */
export async function QUERY(
  path,
  arg1_name,
  arg1_value,
  arg2_name,
  arg2_value,
  arg3_name,
  arg3_value,
  arg4_name,
  arg4_value,
  arg5_name,
  arg5_value,
  arg6_name,
  arg6_value,
  arg7_name,
  arg7_value,
  arg8_name,
  arg8_value,
  arg9_name,
  arg9_value,
  arg10_name,
  arg10_value,
  arg11_name,
  arg11_value,
  arg12_name,
  arg12_value,
  arg13_name,
  arg13_value,
  arg14_name,
  arg14_value,
  arg15_name,
  arg15_value,
  arg16_name,
  arg16_value,
  arg17_name,
  arg17_value,
  arg18_name,
  arg18_value,
  arg19_name,
  arg19_value,
  arg20_name,
  arg20_value,
  invocation,
  ) {
  const params = {};  
  params[arg1_name] = arg1_value;
  params[arg2_name] = arg2_value;
  params[arg3_name] = arg3_value;
  params[arg4_name] = arg4_value;
  params[arg5_name] = arg5_value;
  params[arg6_name] = arg6_value;
  params[arg7_name] = arg7_value;
  params[arg8_name] = arg8_value;
  params[arg9_name] = arg9_value;
  params[arg10_name] = arg10_value;
  params[arg11_name] = arg11_value;
  params[arg12_name] = arg12_value;
  params[arg13_name] = arg13_value;
  params[arg14_name] = arg14_value;
  params[arg15_name] = arg15_value;
  params[arg16_name] = arg16_value;
  params[arg17_name] = arg17_value;
  params[arg18_name] = arg18_value;
  params[arg19_name] = arg19_value;
  params[arg20_name] = arg20_value;
  
  // Remove any empty parameters:
  for (var key in params) {
    if (params[key] == "" || params[key] === undefined || params[key] === null || key === undefined || key === null || key == "") {
      delete params[key];
    }
  }

  // Parse built in parameters:
  var include_headers = params.hasOwnProperty("include_headers") ? params["include_headers"] : true;
  delete params["include_headers"];  
  var columns = params.hasOwnProperty("columns") ? params["columns"] : null;
  delete params["columns"];
  var as_date_columns = params.hasOwnProperty("as_date_columns") ? params["as_date_columns"] : null;
  delete params["as_date_columns"];
  var sort = params.hasOwnProperty("sort") ? params["sort"] : null;
  delete params["sort"];
  var refresh = params.hasOwnProperty("refresh") ? params["refresh"] : 0;
  delete params["refresh"];

  console.log("here");
  //invocation.setResult([[1,2],[3,5]]);

  // // Run the query immediately:
  const arr = await helper(path, params, include_headers, columns, sort, as_date_columns);
  invocation.setResult(arr);

  if (refresh > 0) {  
    const timer = setInterval(async () => {
      if (window.localStorage.getItem("status") == "paused") {
        console.log("paused!")
        return;
      }
      var arr = await helper(path, params, include_headers, columns, sort, as_date_columns);
      invocation.setResult(arr);
    }, 1000*60*refresh);
    invocation.onCanceled = () => {
      clearInterval(timer);
    };
  }

}

async function helper(path, params, include_headers, columns, sort, as_date_columns) {

  params["api_key"] =  window.localStorage.getItem("apikey");

  var date = new Date();
  var time = date.toLocaleTimeString();  
  var urlparam = Object.entries(params).flatMap(([k, v]) => Array.isArray(v) ? v.map(e => `${k}=${encodeURIComponent(e)}`) : `${k}=${encodeURIComponent(v)}`).join("&");
  var url = `https://app.isostream.io/api${path}?${urlparam}`;
  console.log("[" + time + "] Calling " + url);

  var _start = new Date();
  var options = { method: "GET", mode: "cors"};
  var response = (await fetch(url, options));
  
  var _elapsed = new Date() - _start;
  console.log(`REST call took ${_elapsed/1000} seconds`)

  var _start = new Date();
  var text = await response.text();
  var data = JSON.parse(text);

  

  // Log the response if it's not okay:
  if (!(response.ok)) {
    console.log("error in response");
    return [[text]];
  }
  // If the data is empty, return an empty list:
  if (data.length === 0) {
    console.log("empty response");
    return [[]];
  }

  // Convert the list of objects to a list of lists:
  var keys = Object.keys(data[0]);
  var arr = data.map((data) => Object.values(data));

  // If columns variable is not null, return only the columns specified:
  if (columns !== null) {
    columns = columns.replace(" ", "").split(",");
    var col_indexes = [];
    for (var i = 0; i < columns.length; i++) {
      col_indexes.push(keys.indexOf(columns[i]));
    }
    arr = arr.map((row) => col_indexes.map((i) => row[i]));
    keys = columns;
  }

  // If as_date_columns variable is not null, convert the columns specified to dates (in Excel):
  if (as_date_columns !== null) {
    as_date_columns = as_date_columns.replace(" ", "").split(",");
    var col_indexes = [];
    for (var i = 0; i < as_date_columns.length; i++) {
      col_indexes.push(keys.indexOf(as_date_columns[i]));
    }
    // Convert the columns to dates and leave the other columns the same:
    arr = arr.map((row) => {
      return row.map((col, i) => {
        if (col_indexes.includes(i)) {
          return convertDate(col);
        } else {
          return col;
        }
      });
    });
  }    

  if (sort !== null) {
    // Sort by the column specified:
    if (sort[0] == "-") {
      var sort_index = keys.indexOf(sort.slice(1));
      arr.sort((a, b) => b[sort_index] - a[sort_index]);
    } else {
      var sort_index = keys.indexOf(sort);
      arr.sort((a, b) => a[sort_index] - b[sort_index]);
    }
  }

  if (include_headers) {
    arr.unshift(keys);
  }

  var _elapsed = new Date() - _start;
  console.log(`Processing took ${_elapsed/1000} seconds`)
  console.log(arr);
  return arr;
}
CustomFunctions.associate("QUERY", QUERY);